<template>
  <pp-status-banner
    status="error"
    title="Invalid Link"
    text="This registration link has expired. Please contact the practice to request a new registration form."
  />
</template>

<script>
export default {
  name: 'ViewsError'
}
</script>
